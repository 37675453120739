import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SnackBarErrorConfig } from 'src/app/_utils/_material-import/snackbar-config';
import { isPlatformBrowser } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsuarioService } from 'src/app/_services/usuario.service';
import { BloqueosService } from 'src/app/_services/bloqueos.service';
import { PersonaTipoBloqueo } from 'src/app/bloqueos/_models/persona-tipo-bloqueo';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard 
{
  personaTipoBloqueos: PersonaTipoBloqueo[];
  constructor(   
    @Inject(PLATFORM_ID) private _platformId,
    private _router: Router, 
    private _matSnackBar: MatSnackBar,
    private _usuarioService: UsuarioService,
    private _bloqueoService: BloqueosService,
  ) { 

  }

  canActivate(
    next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
  {
    if (isPlatformBrowser(this._platformId))
    {
      if (this._usuarioService.isAuthenticated())
      {
        this._bloqueoService.getBloqueos().subscribe({
          next: (r) => {
                this.personaTipoBloqueos = r;
                //Si tiene algun tipo de bloqueo y de los bloqueos que tiene está activo el de general se le redirige a la pantalla de cuenta bloqueada
                let exist: boolean = false;
                if(this.personaTipoBloqueos != null){
                  exist = this.personaTipoBloqueos.some(bloqueo => bloqueo.tipoBloqueoId === 'general' && bloqueo.isBloqueado === true);
                }
                if(exist){
                  //Cerramos la sesion al usuario
                  this._usuarioService.logOut();
                  this._router.navigateByUrl('login/cuenta-bloqueada');
                }
          },
          error: () => {
              return false;
          },
        });
      }
      else
      {
        this._router.navigateByUrl(`login?returnUrl=${state.url}`);
        this._matSnackBar.open(new SnackBarErrorConfig().getErrorMsg("sessionExpirada"), "x", new SnackBarErrorConfig());
        return false;
      }
    }
    return true;
  }
}
